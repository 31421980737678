// import React, { useEffect, useState } from 'react';
// import { Modal, Button, Table } from 'react-bootstrap';
// import { useSelector, useDispatch } from 'react-redux';
// import apiFc from '../../services/APIFc';
// import { Box } from '@mui/material';
// import SliderMui from "@mui/material/Slider";
// import { setPedidosSeguimientoCancelado, setPedidosDespachados, actualizarPedidos, actualizarPedidosDespachados } from '../../redux/actions/pedido';

// const ModalPedidoInicio = ({
//   basic,
//   setBasic,
//   transportesDisponibles,
//   puntosRecorrido,
//   actualizarPuntosRecorrido,
//   setSliderValue,
//   imagenEstadoPedido
// }: {
//   basic: boolean;
//   setBasic: React.Dispatch<React.SetStateAction<boolean>>;
//   transportesDisponibles: any[];
//   puntosRecorrido: any;
//   actualizarPuntosRecorrido: (pedidoNroMovVta: string, newValue: number) => void;
//   setSliderValue: React.Dispatch<React.SetStateAction<number>>;
//   imagenEstadoPedido: (idEstado: number) => string;
// }) => {
//   const dispatch = useDispatch();
//   const [pedidos, setPedidos] = useState<any[]>([]);
//   const pedidosDespachados = useSelector((state: any) => state.pedidosDespachados || []);
//   const pedidosSeguimientoCancelado = useSelector((state: any) => state.pedidosSeguimientoCancelado || []);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     const pedidosCancelados = JSON.parse(localStorage.getItem('pedidosSeguimientoCancelado') || '[]');
//     if (pedidosCancelados.length > 0) {
//       pedidosCancelados.forEach((pedidoNroMovVta: string) => {
//         dispatch(setPedidosSeguimientoCancelado(pedidoNroMovVta));
//       });
//     }
//   }, [dispatch]);

//   const obtenerPedidos = () => {
//     apiFc.get('/web/pedido/pedidos/historico?page=0&rows=10')
//       .then(res => {
//         const pedidosObtenidos = res.data.content;
//         dispatch(actualizarPedidos(pedidosObtenidos));

//         const pedidosCancelados = JSON.parse(localStorage.getItem('pedidosSeguimientoCancelado') || '[]');

//         const pedidosFiltrados = pedidosObtenidos.filter((pedido: any) => {
//           const isCancelled = pedidosCancelados.includes(pedido.pedidoNroMovVta);
//           const hasEstado = pedido.pedidoEstado;
//           const isEstadoExcluded = hasEstado && [2, 50, 20, 21, 22, -70, -101, -102].includes(pedido.pedidoEstado.pedidoEstadoId);
//           return !isCancelled && hasEstado && !isEstadoExcluded;
//         });

//         setPedidos(pedidosFiltrados);

//       })
//       .catch(error => {
//         console.log("Error");
//       });
//   };

//   useEffect(() => {
//     if (basic) {
//       obtenerPedidos();
//     }
//   }, [basic]);

//   const handleRecibirPedido = (pedidoNroMovVta: any): void => {
//     apiFc.post(`/web/pedido/pedidos/recibir?nmv=${pedidoNroMovVta}`, {})
//       .then(() => {
//         dispatch(actualizarPedidosDespachados(pedidosDespachados));
//         obtenerPedidos();
//       })
//       .catch(res => {
//         console.log("Error");
//       });
//   };

//   const handleDejarDeSeguir = (pedidoNroMovVta: string) => {
//     // guardo en Redux
//     dispatch(setPedidosSeguimientoCancelado(pedidoNroMovVta));
//     // guardo en localStorage
//     const pedidosCancelados = JSON.parse(localStorage.getItem('pedidosSeguimientoCancelado') || '[]');
//     pedidosCancelados.push(pedidoNroMovVta);
//     localStorage.setItem('pedidosSeguimientoCancelado', JSON.stringify(pedidosCancelados));
//     // actualizo pedidos despachados
//     const nuevosPedidosDespachados = pedidosDespachados.filter((pedido: any) => pedido.pedidoNroMovVta !== pedidoNroMovVta);
//     dispatch(actualizarPedidosDespachados(nuevosPedidosDespachados));
//     // actualizo localmente
//     setPedidos(prevPedidos => prevPedidos.filter(pedido => pedido.pedidoNroMovVta !== pedidoNroMovVta));
//   };

//   const handleClose = () => {
//     setBasic(false);
//   };

//   const marksrv = [
//     { value: 0, id: 1, label: "Enviado" },
//     { value: 2, id: 2, label: "Recibido" },
//     { value: 4, id: 3, label: "Demorado" },
//     { value: 6, id: 4, label: "Procesando" },
//     { value: 8, id: 5, label: "Embalado" },
//     { value: 10, id: 6, label: "Facturado" },
//     { value: 12, id: 10, label: "Despachado" },
//   ];

//   const mobileMarksrv = [
//     { value: 0, id: 1, label: "Enviado" },
//     { value: 6, id: 4, label: "Procesando" },
//     { value: 12, id: 10, label: "Despachado" },
//   ];

//   const pedidosFiltrados = pedidos.filter(pedido => {
//     const isCancelled = pedidosSeguimientoCancelado.includes(pedido.pedidoNroMovVta);
//     const hasEstado = pedido.pedidoEstado;
//     const isEstadoExcluded = hasEstado && [2, 50, 20, 21, 22, -70, -101, -102].includes(pedido.pedidoEstado.pedidoEstadoId);
//     return !isCancelled && hasEstado && !isEstadoExcluded;
//   });

//   if (!basic || pedidosFiltrados.length === 0) {
//     return null;
//   }

//   const puntosAMostrar = windowWidth < 1000 ? 3 : Object.keys(puntosRecorrido).length;

//   interface RangeSlider2Props {
//     value: number[];
//     onChange: (newValue: number) => void;
//     pedidoEstadoId: number;
//   }

//   const RangeSlider2: React.FC<RangeSlider2Props> = ({ value, onChange, pedidoEstadoId }) => {
//     const valuetext2 = (value: number) => {
//       const selectedMark = windowWidth < 1000 ? mobileMarksrv.find(mark => mark.value === value) : marksrv.find(mark => mark.value === value);
//       return selectedMark ? selectedMark.label : '';
//     };

//     const markValue = marksrv.find(mark => mark.id === pedidoEstadoId)?.value || 0;
//     const puntosRecorrido = windowWidth < 1000 ? mobileMarksrv : marksrv;

//     return (
//       <Box className="wd-90p d-flex m-auto">
//         <SliderMui
//           aria-label="Restricted values"
//           max={12}
//           value={[markValue]}
//           valueLabelFormat={valuetext2}
//           getAriaValueText={valuetext2}
//           step={null}
//           valueLabelDisplay="auto"
//           marks={puntosRecorrido}
//           track="inverted"
//           onChange={(event, newValue) => {
//             const selectedMark = puntosRecorrido.find(mark => mark.value === newValue);
//             if (selectedMark) {
//               onChange(selectedMark.id);
//             }
//           }}
//         />
//       </Box>
//     );
//   };

//   return (

//     <Modal show={basic && pedidosFiltrados.length > 0} onHide={handleClose} size="lg">
//       <Modal.Header closeButton>
//         <Modal.Title>Envío de Pedido</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {pedidosFiltrados.length === 0 ? (
//           <p>No hay pedidos para mostrar.</p>
//         ) : (
//           <Table className="table mg-b-0 text-md-nowrap">
//             <thead>
//               <tr>
//                 <th>Pedidos</th>
//               </tr>
//             </thead>
//             <tbody>
//               {pedidosFiltrados.map((pedido: any) => (
//                 <tr key={pedido.pedidoNroMovVta} className="mb-3">
//                   <td>
//                     <strong>Fecha pedido:</strong> {new Date(pedido.pedidoFecha).toLocaleDateString('es-ES')}
//                     <br />
//                     <strong>NMV:</strong> {pedido.pedidoNroMovVta}
//                     <br />
//                     <strong>Transporte:</strong> {
//                       Array.isArray(transportesDisponibles) && transportesDisponibles.find(
//                         (transporte: any) => transporte.transporteID == pedido.transportePedidoID
//                       )?.transporteNombre || pedido.transportePedidoID
//                     }
//                     <br />
//                     <img
//                       src={require(`../../assets/img/pedido/${imagenEstadoPedido(pedido?.pedidoEstado?.pedidoEstadoId)}`)}
//                       alt=""
//                       width="32"
//                       height="32"
//                       className="br-2"
//                     />
//                      <RangeSlider2
//                       value={[puntosRecorrido[pedido.pedidoNroMovVta]]}
//                       onChange={(newValue:any) => {
//                         actualizarPuntosRecorrido(pedido.pedidoNroMovVta, newValue);
//                         setSliderValue(newValue);
//                       }}
//                       pedidoEstadoId={pedido.pedidoEstado.pedidoEstadoId}
//                     />
//                     <br />
//                     <div className="text-center mt-3">
//                       {pedido.pedidoEstado.pedidoEstadoId === 10 && (
//                         <Button variant="primary" className="mg-r-10" onClick={() => handleRecibirPedido(pedido.pedidoNroMovVta)}>
//                           Confirmar Recepción
//                         </Button>
//                       )}
//                       {pedido.pedidoEstado.pedidoEstadoId !== 10 && (
//                         <Button variant="primary" onClick={() => handleDejarDeSeguir(pedido.pedidoNroMovVta)}>Dejar de Seguir</Button>
//                       )}
//                     </div>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         )}
//         <div className="text-center mt-3">
//           <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default ModalPedidoInicio;

import React, { useEffect, useState } from "react";
import { Modal, Button, Card, Col, Row, Accordion } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import apiFc from "../../services/APIFc";
import { Box } from "@mui/material";
import SliderMui from "@mui/material/Slider";
import {
  setPedidosSeguimientoCancelado,
  setPedidosDespachados,
  actualizarPedidos,
  actualizarPedidosDespachados,
} from "../../redux/actions/pedido";
import { isUserLogued } from "../../functions/Procedimientos";

const ModalPedidoInicio = ({
  basic,
  setBasic,
  transportesDisponibles,
  puntosRecorrido,
  actualizarPuntosRecorrido,
  setSliderValue,
  imagenEstadoPedido,
}: {
  basic: boolean;
  setBasic: React.Dispatch<React.SetStateAction<boolean>>;
  transportesDisponibles: any[];
  puntosRecorrido: any;
  actualizarPuntosRecorrido: (
    pedidoNroMovVta: string,
    newValue: number
  ) => void;
  setSliderValue: React.Dispatch<React.SetStateAction<number>>;
  imagenEstadoPedido: (idEstado: number) => string;
}) => {
  const dispatch = useDispatch();
  const [pedidos, setPedidos] = useState<any[]>([]);
  const pedidosDespachados = useSelector(
    (state: any) => state.pedidosDespachados || []
  );
  const pedidosSeguimientoCancelado = useSelector(
    (state: any) => state.pedidosSeguimientoCancelado || []
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const pedidosCancelados = JSON.parse(
      localStorage.getItem("pedidosSeguimientoCancelado") || "[]"
    );
    if (pedidosCancelados.length > 0) {
      pedidosCancelados.forEach((pedidoNroMovVta: string) => {
        dispatch(setPedidosSeguimientoCancelado(pedidoNroMovVta));
      });
    }
  }, [dispatch]);

  const obtenerPedidos = () => {
    apiFc
      .get("/web/pedido/pedidos/historico?page=0&rows=10")
      .then((res) => {
        const pedidosObtenidos = res.data.content;
        dispatch(actualizarPedidos(pedidosObtenidos));

        const pedidosCancelados = JSON.parse(
          localStorage.getItem("pedidosSeguimientoCancelado") || "[]"
        );

        const pedidosFiltrados = pedidosObtenidos.filter((pedido: any) => {
          const isCancelled = pedidosCancelados.includes(
            pedido.pedidoNroMovVta
          );
          const hasEstado = pedido.pedidoEstado;
          const isEstadoExcluded =
            hasEstado &&
            [2, 50, 20, 21, 22, -70, -101, -102].includes(
              pedido.pedidoEstado.pedidoEstadoId
            );
          return !isCancelled && hasEstado && !isEstadoExcluded;
        });

        setPedidos(pedidosFiltrados);
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  useEffect(() => {
    if (basic) {
      obtenerPedidos();
    }
  }, [basic]);

  const handleRecibirPedido = (pedidoNroMovVta: any): void => {
    apiFc
      .post(`/web/pedido/pedidos/recibir?nmv=${pedidoNroMovVta}`, {})
      .then(() => {
        dispatch(actualizarPedidosDespachados(pedidosDespachados));
        obtenerPedidos();
      })
      .catch((res) => {
        console.log("Error");
      });
  };

  const handleDejarDeSeguir = (pedidoNroMovVta: string) => {
    dispatch(setPedidosSeguimientoCancelado(pedidoNroMovVta));
    const pedidosCancelados = JSON.parse(
      localStorage.getItem("pedidosSeguimientoCancelado") || "[]"
    );
    pedidosCancelados.push(pedidoNroMovVta);
    localStorage.setItem(
      "pedidosSeguimientoCancelado",
      JSON.stringify(pedidosCancelados)
    );
    const nuevosPedidosDespachados = pedidosDespachados.filter(
      (pedido: any) => pedido.pedidoNroMovVta !== pedidoNroMovVta
    );
    dispatch(actualizarPedidosDespachados(nuevosPedidosDespachados));
    setPedidos((prevPedidos) =>
      prevPedidos.filter((pedido) => pedido.pedidoNroMovVta !== pedidoNroMovVta)
    );
  };

  const handleClose = () => {
    setBasic(false);
  };

  const pedidosFiltrados = pedidos.filter((pedido) => {
    const isCancelled = pedidosSeguimientoCancelado.includes(
      pedido.pedidoNroMovVta
    );
    const hasEstado = pedido.pedidoEstado;
    const isEstadoExcluded =
      hasEstado &&
      [2, 50, 20, 21, 22, -70, -101, -102].includes(
        pedido.pedidoEstado.pedidoEstadoId
      );
    return !isCancelled && hasEstado && !isEstadoExcluded;
  });

  if (!basic || pedidosFiltrados.length === 0) {
    return null;
  }

  return (
    <div className="wd-100p">
      
      {!isUserLogued && (
  <Col lg={12} md={12}>
    <Card>
      {/* <Card.Body> */}
      {/* <div>
      <h6 className="card-title mb-1">Tus pedidos</h6>
    </div> */}
      <Accordion defaultActiveKey="0" id="accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Tus Pedidos</Accordion.Header>
          <Accordion.Body className="borders">
            <Row>
              {pedidosFiltrados.map((pedido: any) => (
                <Col
                  xs={12}
                  sm={6}
                  md={5}
                  lg={4}
                  xl={3}
                  key={pedido.pedidoNroMovVta}
                >
                  <Card className="bg-primary text-white mb-3">
                    <Card.Body className="card-background-color d-flex justify-content-between align-items-center ht-120">
                      {/* Icono en el lado izquierdo */}
                      <img
                        src={require(`../../assets/img/pedido/${imagenEstadoPedido(
                          pedido?.pedidoEstado?.pedidoEstadoId
                        )}`)}
                        alt=""
                        width="60"
                        height="80"
                        className="br-2 mg-l-20 icon-pedidos-inicio"
                      />
                      {/* Texto y botón en una columna del lado derecho */}
                      <div className="d-flex flex-column text-right card-text-color">
                        <div>
                          <strong>Fecha pedido:</strong>{" "}
                          {new Date(pedido.pedidoFecha).toLocaleDateString(
                            "es-ES"
                          )}
                          <br />
                          <strong>NMV:</strong> {pedido.pedidoNroMovVta}
                          <br />
                          <strong>Transporte:</strong>{" "}
                          {(Array.isArray(transportesDisponibles) &&
                            transportesDisponibles.find(
                              (transporte) =>
                                transporte.transporteID ===
                                pedido.transportePedidoID
                            )?.transporteNombre) ||
                            pedido.transportePedidoID}
                        </div>

                        {/* Botón debajo del texto */}
                        {pedido.pedidoEstado.pedidoEstadoId === 10 && (
                          <Button
                            variant="primary"
                            className="mt-2"
                            onClick={() =>
                              handleRecibirPedido(pedido.pedidoNroMovVta)
                            }
                          >
                            Confirmar Recepción
                          </Button>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* </Card.Body> */}
    </Card>
  </Col>
)}
    </div>
  );
};

export default ModalPedidoInicio;
